import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Connect Share");
    const urlParams = new URLSearchParams(window.location.search);
    const imagename = urlParams.get('q'); 
    const img = document.getElementById("imagebox");

    console.log("IMAGE",imagename);
  
    var imghtml = '<img crossorigin="anonymous" src="https://fu2c.s3.eu-west-1.amazonaws.com/'+imagename+'" id="image" class="w-full m-auto" data-imgsrc="'+imagename+'" data-controller="image" data-action="load->image#draw">'
    img.innerHTML  = imghtml;
  }

  instashare() {
    const image = document.getElementById("image");
    
    const logo = document.getElementById('logo');

    var c = document.createElement('canvas');
    c.id = "CursorLayer";
    c.width = image.naturalWidth;
    c.height = image.naturalHeight;
    c.style.border = "1px solid";
    c.style.display= "none";
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(c);
    var ctx = c.getContext("2d");
    console.log(image);
    console.log(image.naturalHeight);
    console.log(image.naturalWidth);
    
    ctx.drawImage(image, 0,0,image.naturalWidth, image.naturalHeight);
    if (image.dataset.imgsrc != "justdonate.jpg") {
    //ctx.drawImage(logo, 20, image.naturalHeight-20-0.5782312925*image.naturalHeight/2,image.naturalWidth/2,0.5782312925*image.naturalWidth/2);
    }



    c.toBlob((blob) => {
      const filesArray = [
        new File([blob], 'fu2c.png', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        }),
      ];
      navigator.share({
          files: filesArray,
      }).catch((e) => { console.log(e) }); 
    })
  }
  metashare() {
      navigator.share({
          url: "https://faceuptocancer.ie",
          title: "Face Up To Cancer",
          text: "I just added my selfie to Face Up to Cancer! Join me and add yours; become part of the bigger picture - fund research,  services and help save lives. Let’s Face Up To Cancer together. "
      }).catch((e) => { console.log(e) }); 
  }
  whatsappshare() {
    const image = document.getElementById("image");
    
    const logo = document.getElementById('logo');

    var c = document.createElement('canvas');
    c.id = "CursorLayer";
    c.width = image.naturalWidth;
    c.height = image.naturalHeight;
    c.style.border = "1px solid";
    c.style.display= "none";
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(c);
    var ctx = c.getContext("2d");
    console.log(image);
    console.log(image.naturalHeight);
    console.log(image.naturalWidth);
    
    ctx.drawImage(image, 0,0,image.naturalWidth, image.naturalHeight);
    if (image.dataset.imgsrc != "justdonate.jpg") {
     // ctx.drawImage(logo, 20, image.naturalHeight-20-0.5782312925*image.naturalHeight/2,image.naturalWidth/2,0.5782312925*image.naturalWidth/2);
    }



    c.toBlob((blob) => {
      const filesArray = [
        new File([blob], 'futc.png', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        }),
      ];
      let x = image.dataset.imgsrc
      navigator.share({
          files: filesArray,
          url: "https://donate.faceuptocancer.ie/metashare?q="+image.dataset.imgsrc,
          title: "Face Up To Cancer",
          text: "I just added my selfie to Face Up to Cancer! Join me and add yours; become part of the bigger picture - fund research,  services and help save lives. Let’s Face Up To Cancer together. "
      }).catch((e) => { console.log(e) }); 
    })
  }
  share() {
    const image = document.getElementById("image");
    
    const logo = document.getElementById('logo');

    var c = document.createElement('canvas');
    c.id = "CursorLayer";
    c.width = image.naturalWidth;
    c.height = image.naturalHeight;
    c.style.border = "1px solid";
    c.style.display= "none";
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(c);
    var ctx = c.getContext("2d");
    console.log(image);
    console.log(image.naturalHeight);
    console.log(image.naturalWidth);
    
    ctx.drawImage(image, 0,0,image.naturalWidth, image.naturalHeight);
    if (image.dataset.imgsrc != "justdonate.jpg") {
      //ctx.drawImage(logo, 20, image.naturalHeight-20-0.5782312925*image.naturalHeight/2,image.naturalWidth/2,0.5782312925*image.naturalWidth/2);
    }



    c.toBlob((blob) => {
      const filesArray = [
        new File([blob], 'futc.png', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        }),
      ];
      navigator.share({
          files: filesArray,
          url: "https://faceuptocancer.ie",
          title: "Face Up To Cancer",
          text: "I just added my selfie to Face Up to Cancer! Join me and add yours; become part of the bigger picture - fund research,  services and help save lives. Let’s Face Up To Cancer together. "
      }).catch((e) => { console.log(e) }); 
    })
  }
}
