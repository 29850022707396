import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    console.log("tag")
    window.dataLayer.push({'event': this.element.dataset.event }); 
  }
  push (e) {
    window.dataLayer.push({'event': e.target.dataset.event, 'amount':e.target.dataset.amount}); 
    console.log("Pushing event",e.target.dataset.event,e.target.dataset.amount);
  }
}
