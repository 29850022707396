// https://elliott-king.github.io/2020/09/s3-heroku-rails/
import { Controller } from "@hotwired/stimulus"
  import CryptoJS from 'crypto-js'
export default class extends Controller {

  static targets = [ "uploadSection", "donateSection", "results" ]


  static values = {
    host: String
  }
  
  connect() {
    //console.log("Upload controller!", this.element)
  }

  upload() {
    this.preview();
    this.uploadSectionTarget.classList.remove("hidden");
    this.donateSectionTarget.classList.add("hidden");
  }
  next() {
    this.preview();
    this.uploadSectionTarget.classList.add("hidden");
    this.donateSectionTarget.classList.remove("hidden");
    const imgPreview = document.getElementById("preview");
    imgPreview.innerHTML = '';
  }

  async doupload() {
    //console.log("Starting upload");
    const input = document.getElementById("image");
    //const file = input.files[0];
    self.cropme.crop({type: 'base64',width: 600}).then(async b=> {
      let blob = await fetch(b).then(r => r.blob());
      //console.log("Blob",blob);
      const file = new File([blob], 'fu2c.png', {
          type: 'image/png',
          lastModified: new Date().getTime(),
        })

      //console.log("File",file);
      
      const checksum = await this.fileChecksum(file);
      const presignedFileParams = await this.createPresignedUrl(file, file.size, checksum).catch((error)=>{
        //console.log(error);
        window.alert("There was an unexpected problem. Please try again");
      }) ;

     const s3PutOptions = {
        method: 'PUT',
        headers: presignedFileParams.direct_upload.headers,
        body: file,
      }
      let awsRes = await fetch(presignedFileParams.direct_upload.url, s3PutOptions);
      //console.log(awsRes);
      //console.log(awsRes.url);
      if (awsRes.status !== 200) return awsRes
      //console.log("Ending upload");
      this.dispatch("upload", { detail: { content: awsRes.url} })
    })
  }
  

  preview() {
    const input = document.getElementById("image");
    const imgPreview = document.getElementById("preview");
    //console.log(input);
    const files = input.files[0];
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        imgPreview.style.display = "block";
        imgPreview.innerHTML = '<img id="previewimage" src="' + this.result + '" data-controller="crop"/>';
        let e= document.getElementById('previewimage');
        
        self.cropme = new Cropme(e, {
          viewport: {
            width: 250,
            height: 250,
            type: 'square',
            border: {
              enable: true,
              width: 4,
              color: '#FEBD15'
            }
          },
          "zoom": {
            "enable": true,
            "mouseWheel": true,
            "slider": false
          },
          "rotation": {
            "slider": false,
            "enable": false,
            "position": "left"
          },
          "transformOrigin": "image",
          "customClass": 'futccrop'
        });
      });    
    }
  }

  // Note that for larger files, you may want to hash them incrementally.
  // Taken from https://stackoverflow.com/questions/768268/
  md5FromFile(file) {
    // FileReader is event driven, does not return promise
    // Wrap with promise api so we can call w/ async await
    // https://stackoverflow.com/questions/34495796
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
    
      reader.onload = (fileEvent) => {
        let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result)
        const md5 = CryptoJS.MD5(binary)
        resolve(md5)
      }
      reader.onerror = () => {
        reject('oops, something went wrong with the file reader.')
      }
      // For some reason, readAsBinaryString(file) does not work correctly,
      // so we will handle it as a word array
      reader.readAsArrayBuffer(file)
    })
  }

  async fileChecksum(file) {
    const md5 = await this.md5FromFile(file)
    const checksum = md5.toString(CryptoJS.enc.Base64)
    return checksum
  }

  async createPresignedUrl(file, byte_size, checksum){
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        file: {
          filename: file.name,
          byte_size: byte_size,
          checksum: checksum,
          content_type: file.type,
          metadata: {
            'message': 'resume for parsing'
          }
        }
      })
    }
      let res = await fetch(this.hostValue+'/api/upload_key', options)
    if (res.status !== 200) return res
    return await res.json()
  }
}
