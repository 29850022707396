import { Controller } from "@hotwired/stimulus"
import { Loupe, enableLoupe } from "loupe-js"
import "loupe-js/dist/style.css"

export default class extends Controller {

  static values = {
    drawn: String
  }
  connect() {
    const image = document.getElementById("portrait");
    if(image.complete) {
      this.draw();
    }
    
    console.log("Connect locate", this.drawnValue);
  }

  draw() {
    console.log("Draw");
    if (this.drawnValue=="0") {
      const urlParams = new URLSearchParams(window.location.search);
      const x = urlParams.get('x'); 
      const y = urlParams.get('y'); 
      this.drawnValue = 1;
      this.drawbox(x,y);
    }
  }
  drawbox(x,y) {
    const image = document.getElementById("portrait");
    const imagebox = document.getElementById("imagebox");
    const offset = image.dataset.offset
    console.log("offset",offset)
    var c = document.createElement('canvas');
    c.classList.add("w-full")
    c.id = "CursorLayer";
    c.width = image.naturalWidth;
    c.height = image.naturalHeight;
    c.style.border = "1px solid";
    //c.style.display= "none";
  //  imagebox.appendChild(c);
    var ctx = c.getContext("2d");
    ctx.drawImage(image, 0,0,image.naturalWidth, image.naturalHeight);
    ctx.beginPath();
    ctx.strokeStyle = "red";
    ctx.lineWidth = 10;
    console.log(offset*x,offset*y,offset,offset);
    ctx.strokeRect(offset*x,offset*y,offset,offset);
    image.src = c.toDataURL('image/jpeg')
    this.loupe(image)
  }
  loupe(img) {
    const options = {
      magnification: 2,
      width: 250,
      height: 250,
      additionalClassName: "customLoupeClass",
      style: { boxShadow: "4px 5px 5px 4px rgba(0,0,0,0.5)" },
      shape: "circle",
    }
    const loupe = new Loupe(options) // or just `new Loupe()` to use default options

    enableLoupe(img, img.src, loupe)
  }
}
