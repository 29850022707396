import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Connect Sharep");
  }
  share() {
    navigator.share({
        url: window.location.href,
        title: "Face Up To Cancer",
        text: "I added my selfie to this Face Up to Cancer portrait! Join me and add yours; become part of the bigger picture - fund research,  services and help save lives. Let’s Face Up To Cancer together. "
    }).catch((e) => { console.log(e) }); 
  }
}
