import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js";

export default class extends Controller {
  static targets = [ "id", "mainform"]

   static values = {
    image: String,
    host: String,
    key: String
   }

  connect() {
    console.log("Stripe connect");
  }
  
  setupform(amount) {
    document.getElementById("termscheck").checked = true;

    try {
      fetch(this.hostValue+"/api/stripe?image="+this.imageValue+"&amount="+amount).then((response) => {
          if (response.ok ) {
            return response.json()
          } else {
            throw ("Error")
          }
        }
      ).then((data) =>  {
        this.mainformTarget.classList.add("hidden");
        document.getElementById("donations").classList.add('hidden');
        document.getElementById("justdonatetext").classList.add('hidden');
        let clientSecret = (data.clientsecret);
        loadStripe(this.keyValue).then(stripe => 
        stripe.initEmbeddedCheckout({clientSecret}).then(checkout => checkout.mount('#checkout')));
      }).catch((error)=>{
        console.log(error);
        window.alert("There was an unexpected problem. Please try again");
      })
    } catch (error) {
      console.log(error);
      window.alert("There was an unexpected problem. Please try again");
    }
  }

  justdonate(e){
    console.log("justdonate");
    console.log(e);
    this.imageValue=e.detail.content;
    
    document.getElementById("upload").classList.add('hidden');
    document.getElementById("donate").classList.remove('hidden');
    document.getElementById("image").classList.add('hidden');
    document.getElementById("buttons").classList.add('hidden');
    document.getElementById("cropprose").classList.add('hidden');
    document.getElementById("donations").classList.remove('hidden');
    document.getElementById("terms").classList.remove('hidden');
    document.getElementById("justdonatetext").classList.remove('hidden');
    e.preventDefault();
  }
  showdonate(e){
    console.log("showdonate");
    console.log(e);
    this.imageValue=e.detail.content;
    
    const imgPreview = document.getElementById("preview");
    imgPreview.innerHTML = '<img src="' + this.imageValue.split("?")[0] + '"id="pimage" class="w-2/3 m-auto p-0 m-0"/>';
    document.getElementById("buttons").classList.add('hidden');
    document.getElementById("cropprose").classList.add('hidden');
    document.getElementById("donations").classList.remove('hidden');
    document.getElementById("terms").classList.remove('hidden');
    e.preventDefault();
  }
  other(e) {
    document.getElementById("otherdialog").showModal()
  }

  chooseamount(e) {
    const amount = e.target.dataset.amount
    this.setupform(amount)
  }

  chooseother(e) {
    const amount = document.getElementById("otheramount").value
    document.getElementById("otherdialog").close()
    this.setupform(amount)
  }


}
