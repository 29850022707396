
        import * as module0 from './controllers/crop_controller.js';import * as module1 from './controllers/image_controller.js';import * as module2 from './controllers/locate_controller.js';import * as module3 from './controllers/share_controller.js';import * as module4 from './controllers/sharep_controller.js';import * as module5 from './controllers/stripe_controller.js';import * as module6 from './controllers/tags_controller.js';import * as module7 from './controllers/upload_controller.js'
        const modules = {
            "./controllers/crop_controller.js": module0,
            "./controllers/image_controller.js": module1,
            "./controllers/locate_controller.js": module2,
            "./controllers/share_controller.js": module3,
            "./controllers/sharep_controller.js": module4,
            "./controllers/stripe_controller.js": module5,
            "./controllers/tags_controller.js": module6,
            "./controllers/upload_controller.js": module7,
        };
        export default modules;
      