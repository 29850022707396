import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    console.log("Connected image");
    this.done=0;
    const logo = document.getElementById('logo');
    const image = document.getElementById('logo');
    if (image.complete && logo.complete) {
      this.draw()
    }
  }
  draw() {
      console.log("DRAW");
      const image = document.getElementById("image");
      const logo = document.getElementById('logo');
    if (this.done==0 && image.complete && logo.complete) {
      this.done=1;

      if (image.dataset.imgsrc != "justdonate.jpg") {
        var c = document.createElement('canvas');
        c.id = "CursorLayer";
        c.width = image.naturalWidth;
        c.height = image.naturalHeight;
        c.style.border = "1px solid";
        c.style.display= "none";
        var body = document.getElementsByTagName("body")[0];
        body.appendChild(c);
        var ctx = c.getContext("2d");
        console.log(image);
        console.log(image.naturalHeight);
        console.log(image.naturalWidth);
        
        ctx.drawImage(image, 0,0,image.naturalWidth, image.naturalHeight);
        ctx.drawImage(logo, 20, image.naturalHeight-20-0.5782312925*image.naturalHeight/2,image.naturalWidth/2,0.5782312925*image.naturalWidth/2);

        image.src = c.toDataURL("image/png");
      }
    } 
  }
}
